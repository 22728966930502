
























































































































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_CV_TOP_JOBS,
  GETTER_CV_ID,
  GETTER_CV_SKILLS,
  GETTER_CV_TOP_JOBS,
  GETTER_JOBS_LOADING,
  GETTER_SKILLS_LOADING,
  GET_CV_TOP_JOB_ID,
  UPLOAD_CV,
  SEARCH_JOB,
  GET_CV_UPSKILL_COURSES,
  GETTER_CV_UPSKILL_COURSES,
  GETTER_UPSKILL_COURSES_LOADING,
  UPLOAD_FILE
} from "@/store/modules/skill_extractor_old";
import SearchJob from "./SearchJob.vue";

import Snackbar from "@/components/shared/Snackbar.vue";
export default Vue.extend({
  /**
   * Reactive properties for Home component
   * */
  data: () => ({
    name: "Home",
    file_uploader_label: "Drop it like its Hot.",
    userName: "",
    job_title: null,
    tab: "",
    grey: "lighten-4",
    items: ["web", "shopping", "videos", "images", "news"],
    attrs: {
      class: "mb-4",
      boilerplate: false
    },
    file: null,

    cv_text: "",
    skills_loading: false,
    jobs_loading: false,
    upskill_loading: false,
    upskill_courses_loading: false,
    fetching_jobs: false,
    upskill_job_data: false,
    skills: [],
    jobs: [],
    job_id: null,
    job_score: 0,
    job_gap: [],
    job_match: [],
    job_skills: [],
    loading: false,
    items_job: [
      {
        text: String(""),
        value: String("")
      }
    ],
    search: null,
    select_job: null,
    compare_job_data: null,

    upskill_id: -1,
    is_upskill: false,
    upskills: [],
    upskill_job_title: "",
    upskill_course_title: "",
    upskill_courses: [],
    upskill_matched: [],
    upskill_score: 0,
    upskill_remaining_gap: [],
    is_job_searched: false,
    job_searched_id: null,

    //snakbar
    snackbar_notification: false,
    notification: ""
  }),
  mounted(): void {
    //
  },
  components: {
    SearchJob,
    Snackbar
  },
  watch: {
    cv_skills(val) {
      this.skills = val;
    },
    cv_top_jobs(val) {
      if (val && val.length > 0) {
        this.jobs = val;
        this.extract_job_data(this.jobs[0]);
      }
    },
    getter_skills_loading(val) {
      this.skills_loading = val;
    },
    getter_jobs_loading(val) {
      this.jobs_loading = val;
    },
    search(val) {
      val && val !== this.select_job && this.query_selections(val);
    },
    select_job(val) {
      if (val) {
        this.is_job_searched = true;
        this.job_title = val.text;
        this.job_searched_id = val.value;
      }
    },
    getter_upskill_courses_loading(val) {
      this.upskill_courses_loading = val;
    },
    cv_upskill_courses(val) {
      console.log("cv_upskill_courses", val);
      if (!this.is_job_searched && val) {
        this.extract_upskill(val);
      }
    }
  },
  computed: {
    ...mapGetters("skill_extractor_old", {
      getter_skills_loading: GETTER_SKILLS_LOADING,
      getter_jobs_loading: GETTER_JOBS_LOADING,
      cv_skills: GETTER_CV_SKILLS,
      cv_id: GETTER_CV_ID,
      cv_top_jobs: GETTER_CV_TOP_JOBS,
      cv_top_jobs_id: GET_CV_TOP_JOB_ID,
      cv_upskill_courses: GETTER_CV_UPSKILL_COURSES,
      getter_upskill_courses_loading: GETTER_UPSKILL_COURSES_LOADING
    })
  },
  methods: {
    ...mapActions("skill_extractor_old", {
      upload_cv: UPLOAD_CV,
      get_cv_top_jobs: GET_CV_TOP_JOBS,
      search_job: SEARCH_JOB,
      get_cv_upskill_courses: GET_CV_UPSKILL_COURSES,
      upload_file: UPLOAD_FILE
    }),
    go_back() {
      this.is_job_searched = false;
      this.select_job = null;
      this.is_upskill = false;
      this.upskills = [];
      this.upskill_matched = [];
      this.upskill_remaining_gap = [];
      this.upskill_courses = [];
    },
    async uploadCV() {
      this.fetching_jobs = false;
      this.jobs = [];
      this.job_match = [];
      this.job_gap = [];
      this.job_skills = [];

      this.is_upskill = false;
      this.upskills = [];
      this.upskill_matched = [];
      this.upskill_remaining_gap = [];
      this.upskill_courses = [];
      let payload: any = {
        type: "",
        data: null
      };
      if (this.file && this.tab === "file") {
        payload.type = "file";
        let formData = new FormData();
        let file: any = this.file;
        formData.append("cvFile", file);
        payload.data = formData;
      }
      if (this.cv_text && this.tab === "text") {
        payload.type = "text";
        payload.data = this.cv_text;
      }
      this.show_notification("Extracting Skills. Please wait");
      await this.upload_cv(payload);
      this.hide_notification();
      console.log("this.cv_id", this.cv_id);
      if (this.cv_id) {
        this.fetching_jobs = true;
        this.show_notification("Getting top jobs data. Please wait");
        await this.get_cv_top_jobs(this.cv_id);
        this.hide_notification();
      }
    },
    change_file() {
      console.log("chnage file");
      let file: any = this.file;
      if (file) {
        this.file_uploader_label = file.name;
      }
    },
    job_clicked(index: number) {
      this.extract_job_data(this.jobs[index]);
      this.clear_upskill_data();
    },

    extract_job_data(item: any) {
      this.job_id = item.job_data_index;
      this.job_score = parseInt((item.matching_score * 100).toFixed(0));
      this.job_gap = item.job_gap;
      this.job_match = item.job_match;
      this.job_skills = this.job_match.concat(this.job_gap);
    },
    job_selected_class(index: number) {
      const item: any = this.jobs[index];
      if (item)
        return item.job_data_index === this.job_id ? "job_selected" : null;
      else return null;
    },
    async query_selections(v: any) {
      this.loading = true;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const result = await this.search_job(v);
      if (result) {
        this.items_job = [];
        result.data.forEach(
          function (arrayItem: { job_title: string; jid: string }) {
            self.items_job.push({
              text: arrayItem.job_title,
              value: arrayItem.jid
            });
          }.bind(this)
        );
        this.loading = false;
      }
    },
    up_skill_clicked(index: number) {
      this.upskill_id = index;
      this.extract_upskill_data(this.upskills[index]);
    },
    upskill_selected_class(index: number) {
      if (this.upskill_id !== -1)
        return this.upskill_id === index ? "job_selected" : null;
      else return null;
    },
    async upSkill_gap() {
      this.show_notification("Getting upskill data. Please wait");
      await this.get_cv_upskill_courses({
        response_id: this.cv_id,
        gap_list: this.job_gap
      });
      this.hide_notification();
      this.is_upskill = true;
    },
    clear_upskill_data() {
      this.is_upskill = false;
      this.upskills = [];
      this.upskill_remaining_gap = [];
      this.upskill_matched = [];
      this.upskill_courses = [];
    },
    extract_upskill(data: any) {
      const job: any = this.jobs.find(
        (it: any) => it.job_data_index === this.job_id
      );
      if (job) {
        this.upskill_job_title = this.capitalize(job.job_title);
        this.upskills = data.upskill;
      }
      this.upskill_remaining_gap = data.remaining_skill_gap;
      if (this.upskills && this.upskills.length > 0) {
        this.upskill_id = 0;
        this.extract_upskill_data(this.upskills[0]);
      }
    },
    extract_upskill_data(data: any) {
      this.upskill_course_title = data.title
        ? this.capitalize(data.title[0])
        : "";
      this.upskill_courses = data.course_skills;
      this.upskill_matched = data.matched_skills;
      this.upskill_score = parseInt(
        ((data.matched_skills_count / this.job_gap.length) * 100).toFixed(0)
      );
    },
    capitalize(s: string) {
      return s && s[0].toUpperCase() + s.slice(1);
    },
    show_notification(text: string) {
      this.notification = text;
      this.snackbar_notification = true;
    },
    hide_notification() {
      this.snackbar_notification_click();
    },
    snackbar_notification_click() {
      this.notification = "";
      this.snackbar_notification = false;
    },
    add_drop_file(e: any) {
      this.file = e.dataTransfer.files[0];
      this.change_file();
    }
  }
});
