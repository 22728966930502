


















































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  GET_CV_TOP_JOBS,
  GETTER_CV_ID,
  GETTER_CV_TOP_JOBS,
  GET_CV_TOP_JOB_ID,
  SEARCH_JOB,
  COMPARE_JOB,
  GETTER_COMPARE_RESP,
  GET_CV_UPSKILL_COURSES,
  GETTER_CV_UPSKILL_COURSES,
  GETTER_UPSKILL_COURSES_LOADING
} from "@/store/modules/skill_extractor_old";

import Snackbar from "@/components/shared/Snackbar.vue";

export default Vue.extend({
  /**
   * Reactive properties for Home component
   * */
  data: () => ({
    name: "Home",
    userName: "",
    job_title_local: null,
    tab: "",
    grey: "lighten-4",
    items: ["web", "shopping", "videos", "images", "news"],
    attrs: {
      class: "mb-4",
      boilerplate: false
    },
    file: null,

    cv_text: "",
    skills_loading: false,
    jobs_loading: false,
    upskill_courses_loading: false,
    fetching_jobs: false,
    skills: [],
    jobs: [],
    // job_id: null,
    job_score: 0,
    job_gap: [],
    job_match: [],
    job_skills: [],
    loading: false,
    items_job: [
      {
        text: String(""),
        value: String("")
      }
    ],
    search: null,
    select_job: null,
    compare_job_data: null,

    //upskill
    upskill_id: -1,
    is_upskill: false,
    upskills: [],
    upskill_job_title: "",
    upskill_course_title: "",
    upskill_courses: [],
    upskill_matched: [],
    upskill_score: 0,
    upskill_remaining_gap: [],

    //snakbar
    snackbar_notification: false,
    notification: ""
  }),
  props: {
    job_id: Number,
    job_title: String
  },
  mounted(): void {
    this.job_id && this.compareJob(this.job_id);
  },
  components: {
    Snackbar
  },
  watch: {
    search_job_resp(val) {
      console.log("extract_job_data_from_search", val);
      if (val) {
        this.extract_job_data_from_search(val);
      }
    },
    search(val) {
      val && val !== this.select_job && this.querySelections(val);
    },
    select_job(val) {
      if (val) {
        this.job_title_local = val.text;
        val && this.compareJob(val.value);
      }
    },
    getter_upskill_courses_loading(val) {
      this.upskill_courses_loading = val;
    },
    cv_upskill_courses(val) {
      console.log("cv_upskill_courses", val);
      if (this.job_id && val) {
        this.extract_upskill(val);
      }
    }
  },
  computed: {
    ...mapGetters("skill_extractor_old", {
      cv_id: GETTER_CV_ID,
      cv_top_jobs: GETTER_CV_TOP_JOBS,
      cv_top_jobs_id: GET_CV_TOP_JOB_ID,
      search_job_resp: GETTER_COMPARE_RESP,
      cv_upskill_courses: GETTER_CV_UPSKILL_COURSES,
      getter_upskill_courses_loading: GETTER_UPSKILL_COURSES_LOADING
    })
  },
  methods: {
    ...mapActions("skill_extractor_old", {
      get_cv_top_jobs: GET_CV_TOP_JOBS,
      search_job: SEARCH_JOB,
      compare_job: COMPARE_JOB,
      get_cv_upskill_courses: GET_CV_UPSKILL_COURSES
    }),
    goBack() {
      this.$emit("goBack");
    },

    extract_job_data_from_search(item: any) {
      this.is_upskill = false;
      this.upskills = [];
      this.upskill_matched = [];
      this.upskill_remaining_gap = [];
      this.upskill_courses = [];

      this.job_score = parseInt(
        ((item.info ? item.info.matching_score : 0) * 100).toFixed(0)
      );
      this.job_gap = item.gap;
      this.job_match = item.matched;
      this.job_skills = this.job_match.concat(this.job_gap);
    },
    async querySelections(v: any) {
      this.loading = true;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const result = await this.search_job(v);
      if (result) {
        this.items_job = [];
        result.data.forEach(
          function (arrayItem: { job_title: string; jid: string }) {
            self.items_job.push({
              text: arrayItem.job_title,
              value: arrayItem.jid
            });
          }.bind(this)
        );
        this.loading = false;
      }
    },
    async compareJob(job_id: number) {
      this.fetching_jobs = true;
      this.jobs_loading = true;
      await this.compare_job({ job_id, cv_id: this.cv_id });
      this.jobs_loading = false;
    },
    up_skill_clicked(index: number) {
      this.upskill_id = index;
      this.extract_upskill_data(this.upskills[index]);
    },
    upskill_selected_class(index: number) {
      if (this.upskill_id !== -1)
        return this.upskill_id === index ? "job_selected" : null;
      else return null;
    },
    async upSkill_gap() {
      this.show_notification("Getting upskill data. Please wait");
      await this.get_cv_upskill_courses({
        response_id: this.cv_id,
        gap_list: this.job_gap
      });
      this.hide_notification();
      this.is_upskill = true;
    },
    clear_upskill_data() {
      this.is_upskill = false;
      this.upskills = [];
      this.upskill_remaining_gap = [];
      this.upskill_matched = [];
      this.upskill_courses = [];
    },
    extract_upskill(data: any) {
      const title = this.job_title_local ? this.job_title_local : "";
      this.upskills = data.upskill;
      if (title) {
        this.upskill_job_title = this.capitalize(title);
      }
      this.upskill_remaining_gap = data.remaining_skill_gap;
      if (this.upskills && this.upskills.length > 0) {
        this.upskill_id = 0;
        this.extract_upskill_data(this.upskills[0]);
      }
    },
    extract_upskill_data(data: any) {
      this.upskill_course_title = data.title
        ? this.capitalize(data.title[0])
        : "";
      this.upskill_courses = data.course_skills;
      this.upskill_matched = data.matched_skills;
      this.upskill_score = parseInt(
        ((data.matched_skills_count / this.job_gap.length) * 100).toFixed(0)
      );
    },
    capitalize(s: string) {
      return s && s[0].toUpperCase() + s.slice(1);
    },
    show_notification(text: string) {
      this.notification = text;
      this.snackbar_notification = true;
    },
    hide_notification() {
      this.snackbar_notification_click();
    },
    snackbar_notification_click() {
      this.notification = "";
      this.snackbar_notification = false;
    }
  }
});
